<template>
	<div v-if="!getAdminError" class="table-responsive">
		<table class="table table-hover table-md">
			<caption>List of users</caption>
			<thead>
				<tr>
					<th scope="col">Appointment</th>
					<th scope="col">Name</th>
					<th class="table-action" scope="col">Actions</th>
				</tr>
			</thead>
			<!-- Placeholder data -->
			<tbody v-if="loading" class="table-loading">
				<tr v-for="tr in 5" :key="tr">
					<td v-for="td in 3" :key="td">
						<span></span>
						<span class="sr-only">Loading...</span>
					</td>
				</tr>
			</tbody>
			<!-- User Entries -->
			<tbody v-else>
				<tr
					v-for="entry in orderedEntries"
					:key="entry.id"
				>
					<th scope="row">
						{{ appointmentTime(entry.appointment_timestamp) }}
					</th>
					<td>
						{{ entry.first_name }} {{ entry.last_name }}
					</td>
					<td class="table-action">
						<div class="btn-group" role="group" aria-label="User Actions">
							<router-link
								class="btn btn-primary"
								title="View user"
								:to="{
									name: 'User',
									params: { id: entry.id }
								}"
							>
								<eye-icon size="1x" />
								<span class="sr-only">View</span>
							</router-link>
							<button
								type="button"
								class="btn btn-danger"
								title="Delete user"
								@click="showModal(entry.id)"
							>
								<x-icon size="1x" />
								<span class="sr-only">Delete</span>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<!-- Admin Error -->
	<alert v-else error type="danger">
		<small>{{ getAdminError }}</small>
	</alert>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Icons
import { XIcon, EyeIcon } from 'vue-feather-icons'

// General Components
import alert from '@/components/general/alert'

export default {
	components: {
		XIcon,
		EyeIcon,
		alert
	},
	data() {
		return {
			loading: true,
			error: '',
			entries: []
		}
	},
	computed: {
		...mapGetters([
			'getAdminError',
			'getUserClinic'
		]),
		orderedEntries() {
			let entries = []

			if ( this.entries ) {
				// Get original entries
				const getEntries = this.entries.data
				
				// Sort entries by appointment first
				const sortEntries = getEntries.sort((a, b) =>
					a.appointment_timestamp - b.appointment_timestamp
				)

				entries = sortEntries
			}

			return entries
		}
	},
	watch: {
		entries(value) {
			if ( value != null ) {
				this.loading = false
			}
		},
		getAdminError(value) {
			if ( value != '' ) {
				this.error = value
			}
		}
	},
	methods: {
		async showModal(id) {
			await this.$store.dispatch('setEntry', id)
			await this.$store.dispatch('showModal', true)
		},
		appointmentTime(appointment) {
			let time = ''
			
			// Convert timestamp to to actual time/date
			if ( appointment ) {
				const date = new Date(appointment * 1000)
				const day = date.getDate()
				const month = date.getMonth() + 1
				const year = date.getFullYear()
				time = `${day}/${month}/${year}`
			}

			return time
		},
		async getEntries() {
			const clinic = localStorage.getItem('user-clinic') === null ? this.getUserClinic : localStorage.getItem('user-clinic')
			const date = new Date().toISOString().slice(0,10)
			const result = await this.$api.get(
				`/v1/entries/${clinic}`, {
					params: {
						appointments_from: date
					}
				}
			)

			if ( result ) this.entries = result.data
		}
	},
	created() {
		this.getEntries()
	}
}
</script>