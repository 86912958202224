<template>
	<dashboard-container>
		<section class="h-100">
			<h2 class="h4">List of users</h2>
			
			<!-- User table list -->
			<userList />
		</section>
	</dashboard-container>
</template>

<script>
import dashboardContainer from '@/components/admin/dashboard-container'
import userList from '@/components/admin/user-list'

export default {
	components: {
		dashboardContainer,
		userList,
	},
	methods: {
		resetState() {
			// Remove error and stored entry
			this.$store.dispatch('setEntry', '')
			this.$store.dispatch('setAdminError', '')
		}
	},
	created() {
		this.resetState()
	}
}
</script>